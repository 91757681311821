<template>
  <div class="wrp p-4">
    <p class="fs-24 fw-600 txt-black ml-3">Circle</p>
    <div
      class="pt-5 pl-4 empty-w"
      v-show="
        pendings &&
        !pendings.total &&
        waitings &&
        !waitings.total &&
        accepteds &&
        !accepteds.total &&
        !loading
      "
    >
      <!-- <p class="fs-56 txt-black fw-600">Circle</p> -->
      <p class="fs-20 txt-black mb-40">{{ $t("circle_feature.lbl_circle_message") }}</p>
      <div class="d-flex">
        <button
          class="btn b-empty txt-pri btn--babybluefs-16 fw-600 p-18 mr-2"
          @click="showAdd(true, 'join')"
        >{{ $t("circle_feature.lbl_join_circle") }}</button>
        <button
          class="btn b-empty bg-pri bd-pri text-white fs-16 fw-600 p-18 ml-2"
          @click="showAdd(true, 'create')"
        >{{ $t("circle_feature.btn_create_circle") }}</button>
      </div>
    </div>

    <!-- Danh sách circle người khác mời mình vào -->
    <div
      v-show="
        (pendings && pendings.total) ||
        (waitings && waitings.total) ||
        (accepteds && accepteds.total)
      "
    >
      <div v-show="!(!loading_pending && !pendings.total)" class="p-3">
        <div
          class="d-flex align-items-center cursor-pointer mb-2"
          @click="is_pending = !is_pending"
        >
          <div v-if="pendings && pendings.total">
            <p class="fs-16 fw-600 mb-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="is_pending"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="!is_pending"
              >
                <path d="M9.5 17L14.5 12L9.5 7L9.5 17Z" fill="#1D2939" />
              </svg>
              {{ $t("circle_feature.lbl_invitation") }} ({{
              (pendings && pendings.total) || 0
              }})
            </p>
          </div>
        </div>
        <div class="row" v-show="is_pending && !loading_pending">
          <div
            class="col-md-4 col-xl-3 mb-2"
            v-for="circle in pendings.data"
            :key="circle.id + 'pending'"
            @click="goToCircle(circle.type, circle.id)"
          >
            <CircleListPendingItem
              :circle="circle"
              :loading="loading"
              :type="'pending'"
              @rejectCircle="rejectCircle"
              @acceptCircle="acceptCircle"
            />
          </div>
          <div class="col-12 text-center" v-if="pendings && pendings.data">
            <template v-if="!isLoading">
              <a
                href="javascript:;"
                class="txt-pri robo-16-400"
                v-if="pendings.current_page < pendings.last_page"
                @click="(e) => handleGetMoreData(e, 'pending', true)"
              >{{ $t("circle_feature.btn_load_more") }}</a>
            </template>
            <div class="txt-pri fs-16" v-else>{{ $t('schedule_page.lbl_loading') }}</div>
          </div>
        </div>
        <div class v-if="loading_pending">
          <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
        </div>
      </div>
      <!--Danh sách circle mình gửi yêu cầu và chờ người khác duyệt -->
      <div v-show="!(!loading_waiting && !waitings.total)" class="p-3">
        <div
          class="d-flex align-items-center cursor-pointer mb-2"
          @click="is_waiting = !is_waiting"
        >
          <div v-if="waitings && waitings.total">
            <p class="fs-16 fw-600 mb-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="is_waiting"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="!is_waiting"
              >
                <path d="M9.5 17L14.5 12L9.5 7L9.5 17Z" fill="#1D2939" />
              </svg>
              {{ $t("circle_feature.lbl_approval_wait") }} ({{
              (waitings && waitings.total) || 0
              }})
            </p>
          </div>
        </div>
        <div class="row" v-show="is_waiting && !loading_waiting">
          <div
            class="col-md-4 col-xl-3 mb-2"
            v-for="circle in waitings.data"
            :key="circle.id + 'waiting'"
            @click="goToCircle(circle.type, circle.id)"
          >
            <CircleListPendingItem
              :circle="circle"
              :loading="loading"
              :type="'waiting'"
              @rejectCircle="rejectCircle"
              @acceptCircle="acceptCircle"
            />
          </div>
          <div class="col-12 text-center" v-if="waitings && waitings.data">
            <template v-if="!isLoading">
              <a
                href="javascript:;"
                class="txt-pri fs-16"
                v-if="waitings.current_page < waitings.last_page"
                @click="(e) => handleGetMoreData(e, 'waiting', true)"
              >{{ $t("circle_feature.btn_load_more") }}</a>
            </template>
            <div class="txt-pri fs-16" v-else>{{ $t('schedule_page.lbl_loading') }}</div>
          </div>
        </div>
        <div class v-if="loading_waiting">
          <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
        </div>
      </div>
      <!--Danh sách circle của tôi -->
      <div class="p-3">
        <div class="d-flex align-items-center cursor-pointer mb-2" @click="is_owner = !is_owner">
          <div v-if="accepteds && accepteds.total">
            <p class="fs-16 fw-600 mb-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="is_owner"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="!is_owner"
              >
                <path d="M9.5 17L14.5 12L9.5 7L9.5 17Z" fill="#1D2939" />
              </svg>
              {{ $t("circle_feature.lbl_my_circle") }} ({{
              (accepteds && accepteds.total) || 0
              }})
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-1 my-2">
          <span>{{ $t('lbl_search') }}</span>
          <el-input
            class="flex-1 cs-border"
            v-model="searchValue"
            :placeholder="$t('lbl_enter_circle_name')"
            @change="handleSearchMyCircle"
            clearable
          />
        </div>
        <div class="row" v-show="is_owner && !loading_accepted">
          <div class="col-md-4 col-xl-3 mb-32">
            <div
              class="item-w h-196px d-flex justify-content-center align-items-center cursor-pointer"
              @click="showAdd(true)"
            >
              <div>
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                    fill="#20419B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M41.6 38.3996H52.8C53.6836 38.3996 54.4 39.116 54.4 39.9996C54.4 40.8833 53.6836 41.5996 52.8 41.5996H41.6V52.7996C41.6 53.6833 40.8836 54.3996 40 54.3996C39.1163 54.3996 38.4 53.6833 38.4 52.7996V41.5996H27.2C26.3163 41.5996 25.6 40.8833 25.6 39.9996C25.6 39.116 26.3163 38.3996 27.2 38.3996H38.4V27.1996C38.4 26.316 39.1163 25.5996 40 25.5996C40.8836 25.5996 41.6 26.316 41.6 27.1996V38.3996Z"
                    fill="#20419B"
                  />
                </svg>

                <p
                  class="mb-0 text-center mt-2 txt-black fw-600 fs-18"
                >{{ $t("circle_feature.lbl_add_more") }}</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 col-xl-3 mb-32"
            v-for="circle in myCircles"
            :key="circle.id + 'accepted'"
            @click="goToCircle(circle.type, circle.id)"
          >
            <CircleListItem
              :circle="circle"
              :activeConversation="
                getCvtsByNameId(
                  circle.twilio_conversation &&
                    circle.twilio_conversation.twilio_conv_id
                )
              "
            />
          </div>
          <div class="col-12 text-center" v-if="accepteds && accepteds.data">
            <template v-if="!isLoading">
              <a
                href="javascript:;"
                class="txt-pri fs-16"
                v-if="accepteds.current_page < accepteds.last_page"
                @click="(e) => handleGetMoreData(e, 'accepted', true)"
              >{{ $t("circle_feature.btn_load_more") }}</a>
            </template>
            <div class="txt-pri fs-16" v-else>{{ $t('schedule_page.lbl_loading') }}</div>
          </div>
        </div>

        <div class v-if="loading_accepted">
          <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
        </div>
      </div>
    </div>
    <div class v-if="loading">
      <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
    </div>
    <AddCircle @refresh="refresh" :add_type="add_type" />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import AddCircle from '../../components/Circles/AddCircle.vue'
import CircleListPendingItem from '../../components/Circles/CircleListPendingItem.vue'
import CircleListItem from '../../components/Circles/CircleListItem.vue'
import { CIRCLE_INVITE_TYPE } from '../../utils/constants'
export default {
  name: 'CircleList',
  components: { AddCircle, CircleListPendingItem, CircleListItem },
  data () {
    return {
      is_pending: true,
      is_waiting: true,
      is_owner: true,
      pendings: [],
      waitings: [],
      accepteds: [],
      loading: false,
      add_type: 'create',
      cvsts: [],
      loading_accepted: false,
      loading_pending: false,
      loading_waiting: false,
      current_page_accepted: 1,
      isLoading: false,
      scrollTop: 0,
      CIRCLE_INVITE_TYPE,
      myCircles: [],
      searchValue: ''
    }
  },
  mounted () {
    this.prepare()
    if (this.$route.query.create) {
      this.showAdd(true, 'create')
    }
  },
  watch: {},
  methods: {
    async prepare () {
      try {
        this.loading = true
        await this.getMyCircles('accepted')
        this.getMyCircles('pending')
        this.getMyCircles('waiting')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    getCvtsByNameId (id) {
      if (
        !id ||
        !this.$conversationsList ||
        !this.$conversationsList.group ||
        !this.$conversationsList.group.length
      ) {
        return {}
      }
      let cvsItem = this.$conversationsList?.group?.find(r => r.sid === id)
      return cvsItem ? cvsItem.conversations : {}
    },
    async handleGetMoreData (e, status, isNext) {
      const self = this
      self.scrollTop += e.pageY
      await self.getMyCircles(status, isNext).then(() => {
        document.getElementsByClassName('wrp')[0].scrollTop = self.scrollTop
      })
    },
    async getMyCircles (status, isNext = false) {
      let self = this
      // let params = {
      //   status: status || 'accepted',
      //   page: isNext
      //     ? status === 'pending'
      //       ? self.pendings?.current_page + 1
      //       : status === 'waiting'
      //         ? self.waitings?.current_page + 1
      //         : status === 'accepted'
      //           ? self.accepteds?.current_page + 1
      //           : 1
      //     : 1,
      //   limit: 500,
      //   sort_by: 'desc',
      //   sort: 'created_at'
      // }
      const params = {
        status: status || 'accepted',
        page_num: isNext
          ? status === 'pending'
            ? self.pendings?.current_page + 1
            : status === 'waiting'
              ? self.waitings?.current_page + 1
              : status === 'accepted'
                ? self.accepteds?.current_page + 1
                : 1
          : 1,
        page_size: 1000,
        sort_by: 'desc',
        sort: 'created_at'
      }
      self.isLoading = true
      // self.loadingHandler(status, true)
      await this.$rf
        .getRequest('DoctorRequest')
        .getMyCirclesV2(params)
        .then(r => {
          const responseData = r.data
          if (status) {
            if (status === 'pending') {
              if (!isNext) {
                self.pendings = responseData
              } else {
                if (responseData?.page?.total) {
                  let old = self.pendings.data
                  let newObj = responseData.data
                  self.pendings.data = old.concat(newObj)
                }
              }

              self.pendings.current_page = responseData?.page?.page_num
              self.pendings.total = responseData?.page?.total
              self.pendings.last_page = responseData?.page?.total_page
            }
            if (status === 'waiting') {
              if (!isNext) {
                self.waitings = responseData
              } else {
                if (responseData?.page?.total) {
                  let old = self.waitings.data
                  let newObj = responseData.data
                  self.waitings.data = old.concat(newObj)
                }
              }

              self.waitings.current_page = responseData?.page?.page_num
              self.waitings.total = responseData?.page?.total
              self.waitings.last_page = responseData?.page?.total_page
            }
            if (status === 'accepted') {
              if (!isNext) {
                self.accepteds = responseData
                this.myCircles = responseData?.data
              } else {
                if (responseData?.page?.total) {
                  const old = self.accepteds.data
                  const newObj = responseData.data
                  self.accepteds.data = old.concat(newObj)
                  this.myCircles = old.concat(newObj)
                }
              }

              self.accepteds.current_page = responseData?.page?.page_num
              self.accepteds.total = responseData?.page?.total
              self.accepteds.last_page = responseData?.page?.total_page
              this.current_page_accepted += 1
            }
          } else {
            if (!isNext) {
              self.accepteds = responseData
              this.myCircles = responseData?.data
            } else {
              if (responseData?.page?.total) {
                const old = self.accepteds.data
                const newObj = responseData.data
                self.accepteds.data = old.concat(newObj)
                this.myCircles = old.concat(newObj)
              }
            }

            self.accepteds.current_page = responseData?.page?.page_num
            self.accepteds.total = responseData?.page?.total
            self.accepteds.last_page = responseData?.page?.total_page
            this.current_page_accepted += 1
          }
        })
        .finally(() => {
          // self.loadingHandler(status, false)
          self.isLoading = false
        })
    },
    async getMember (circleId) {
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getMemberByUserId(circleId, appUtils.getLocalUser().id)
      return r
    },

    async acceptCircle (circle) {
      let self = this
      try {
        this.loading = true
        let mem = await this.getMember(circle.id)
        if (mem?.data?.id) {
          await this.$rf
            .getRequest('DoctorRequest')
            .acceptJoinCircle(circle.id)
            .then(r => {
              self.$toast.open({
                message: 'Thành công',
                type: 'success'
              })
              self.$reloadChat++
              self.goToCircle(circle.type, circle.id)
            })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async rejectCircle (circleId, type) {
      let self = this
      this.loadingHandler(type, true)
      try {
        this.loading = true
        let mem = await this.getMember(circleId)
        if (mem?.data?.id) {
          await this.$rf
            .getRequest('DoctorRequest')
            .rejectCircleMember(mem?.data?.id)
            .then(r => {
              self.getMyCircles(type)
              self.$toast.open({
                message: 'Thành công',
                type: 'success'
              })
            })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.loadingHandler(type, false)
      }
    },
    showAdd (show, add_type) {
      this.add_type = add_type
      window.$('#modalAddCircle').modal(show ? 'show' : 'hide')
    },
    refresh () {
      this.getMyCircles('accepted')
      this.getMyCircles('pending')
      this.getMyCircles('waiting')
      this.showAdd(false)
    },
    goToCircle (type, id) {
      if (!id) return
      if (type === 1) {
        this.$router.push({ name: 'CircleDetail', params: { id: id } })
      } else if (type === 2) {
        this.$router.push({ name: 'LiveCircleDetail', params: { id: id } })
      }
    },
    loadingHandler (type, status) {
      if (status) {
        this.loading_accepted = type === 'accepted'
        this.loading_pending = type === 'pending'
        this.loading_waiting = type === 'waiting'
      } else {
        this.loading_accepted =
          type === 'accepted' && this.loading_accepted
            ? false
            : this.loading_accepted
        this.loading_pending =
          type === 'pending' && this.loading_pending
            ? false
            : this.loading_pending
        this.loading_waiting =
          type === 'waiting' && this.loading_waiting
            ? false
            : this.loading_waiting
      }
    },
    handleSearchMyCircle (search) {
      if (search) {
        this.myCircles = this.accepteds?.data?.filter(item =>
          item?.name?.toLowerCase()?.includes(search?.toLowerCase())
        )
      } else {
        this.myCircles = this.accepteds?.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.b-empty {
  width: 50%;
  max-width: 217px;
}
.empty-w {
  width: 100%;
  max-width: 455px;
}
.wrp {
  color: black;
  background-color: #f7f6f6;
  height: calc(100vh - 61px);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.item-w {
  padding: 1.3rem;
  background-color: #ffffff;
  border: 1px solid #edeae9;
  border-radius: 16px;
  cursor: pointer;
}
.h-40px {
  height: 40px;
}
.h-196px {
  height: 196px;
}
.p-18 {
  padding: 12px 16px 12px 16px;
}

::v-deep {
  .cs-border {
    border: 1px solid #e0e0e0 !important;
    border-radius: 10px;

    & input {
      border-radius: 10px !important;
    }
  }
}
</style>

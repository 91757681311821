<template>
  <div class="py-3 container">
    <div  id="circleChat" class="p-3 pb-0 bg-white">
      <div class="d-flex justify-content-between">
        <p class="fs-16 fw-600 txt-black">{{ circle && circle.name }}</p>
        &nbsp;
        <div>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="cursor-pointer"
            @click="goToDetail"
          >
            <g clip-path="url(#clip0_16405_7002)">
              <path
                d="M6.83691 18.4998L18.8367 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.8367 18.4998L6.83691 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_16405_7002">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.836914 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="border"></div>
      <div class="circle-chat-content">
      <Conversation
        v-if="activeConversation"
        :active-conversation="activeConversation"
        :members="circle_members"
      />
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import Conversation from '../../components/Chats/Conversation.vue'
export default {
  name: 'CircleChat',
  components: { Conversation },
  data () {
    return {
      activeConversation: null,
      circle_member: [],
      circle: null,
      circle_members: []
    }
  },
  mounted () {
    this.user_id = appUtils.getLocalUser()?.id
    this.prepare()
  },
  methods: {
    goToDetail () {
      this.$router.push({ name: 'CircleDetail', params: { id: this.$route.params.id } })
    },
    async prepare () {
      try {
        this.loading = true
        let m = await this.getMember()
        let c = await this.showCircle()
        this.getCircleMembers()
        if (((m && m.data && m.data.status === 2) || (c?.data?.created_by === this.user_id)) && this.$conversationsClient && c?.data?.twilio_conversation?.twilio_conv_id) {
          this.createConversation(c?.data?.twilio_conversation?.twilio_conv_id)
        } else {
          this.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
          this.$router.push({ name: 'CircleList' })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getMember () {
      let self = this
      let r = await this.$rf.getRequest('DoctorRequest').getMemberByUserId(this.$route.params.id, appUtils.getLocalUser().id, null, false).then(r => {
        self.circle_member = r.data
        return r
      }).catch(() => {
        return false
      })
      return r
    },
    async getCircleMembers () {
      let self = this
      if (!this.$route.params.id) return
      let params = {
        limit: 10000,
        status: 2
      }
      await this.$rf.getRequest('DoctorRequest').getCircleMembers(this.$route.params.id, params).then((r) => {
        this.circle_members = r?.data?.data
      }).catch((e) => {
        console.log(e)
        self.$toast.open({
          message: this.$t('circle_feature.lbl_permission_msg'),
          type: 'warning'
        })
      }).finally(() => {
      })
    },
    async showCircle () {
      if (!this.$route.params.id) return
      let self = this
      let r = await this.$rf.getRequest('DoctorRequest').showCircle(this.$route.params.id).then((r) => {
        self.circle = r.data
        return r
      }).catch((e) => {
        console.log(e)
        self.$toast.open({
          message: this.$t('circle_feature.lbl_permission_msg'),
          type: 'warning'
        })
        self.$router.push({ name: 'CircleList' })
        return false
      })
      return r
    },
    async createConversation (id) {
      this.activeConversation = await (this.$conversationsClient.getConversationBySid(id))
    }
  }
}
</script>

<style scoped>
#circleChat {
  height: calc(100vh - 106px);
}
.circle-chat-content {
  height: calc(100% - 38px);
}
</style>